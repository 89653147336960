@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,400;0,700;1,400&family=Poppins:wght@400;500;600;700&display=swap');
@import "~@ng-select/ng-select/themes/default.theme.css";

html,
body {
  height: 100%;
}

:root {
  --orange-color: #f28900;
  --blue-color: #0E4BD9;
  --green-color: #4cbfad;
  --white-color: #fff;
  --border-gray: #DDDDDD;
  --font-gray: #ADAEB5;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
  list-style: none;
}

p {
  margin: 0;
}

body {
  font-family: 'Poppins';
}

.flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

form>* {
  width: 100%;
}

form input {
  padding: 22px 0;
  margin-bottom: 28px;
  border: none;
  outline: none;
}

.paging {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background: #ffffff;
  padding: 0 30px;
  height: 80px;
}

.paging div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
}

.paging div select {
  border: 1px solid #7b838f;
  color: #7b838f;
  border-radius: 5px;
  padding: 5px;
  background-color: #ffffff;
}


simple-snack-bar span {
  margin: auto;
  text-align: center;
}

.cdk-overlay-pane {
  justify-content: center;
}

.mat-snack-bar-handset .mat-snack-bar-container {
  max-width: 66vw !important;
  min-width: 320px !important;
}

.error-snackbar {
  --mdc-snackbar-container-color: #FF5D5B !important;
  --mdc-snackbar-supporting-text-color: white !important;
  --mat-mdc-snack-bar-button-color: white !important;
  white-space: pre-wrap
}

.success-snackbar {
  --mdc-snackbar-container-color: #00B03E !important;
  --mdc-snackbar-supporting-text-color: white !important;
  --mat-mdc-snack-bar-button-color: white !important;
  white-space: pre-wrap
}

.info-snackbar {
  --mdc-snackbar-container-color: #00A0FF !important;
  --mdc-snackbar-supporting-text-color: white !important;
  --mat-mdc-snack-bar-button-color: white !important;
  white-space: pre-wrap
}

button:disabled {
  cursor: not-allowed !important;
}

input:disabled {
  cursor: not-allowed !important;
}

button.manage {
  padding: 10px 20px;
  background-color: var(--orange-color);
  color: #ffffff;
  border-radius: 8px;
  border: 0px;
  font-weight: 500;
}

.btn-manage {
  border-radius: 10px;
  border: none;
  background-color: #404347;
  color: #fff;
  padding: 20px 0px;
}

.btn-manage.owned {
  border-radius: 10px;
  border: none;
  background-color: var(--orange-color);
  color: #fff;
  padding: 20px 0px;
}

.fa {
  margin-right: 10px;
}

.cursor-pointer {
  cursor: pointer;
}

.theme-orange .bs-datepicker-head,
.theme-orange .bs-datepicker-body table td span.selected {
  background-color: var(--orange-color) !important;
}

.theme-orange .bs-datepicker-body table td.week span {
  color: var(--orange-color) !important;
}

header {
  height: 100px;
}

.search input {
  width: 100%;
  padding-left: 35px;
  background-image: url("src/assets/img/icons/search.png");
  background-repeat: no-repeat;
  background-position: 7px 23px;
  opacity: 0.7;
  background-size: 20px;
  font-size: 14px;
}

.search input {
  margin-bottom: 0px;
}

.custom-control-input:checked~.custom-control-label::before {
  border-color: #f28900;
  background-color: #f28900;
}

.modal-dialog {
  position: absolute;
  width: auto;
  margin: .5rem;
  pointer-events: none;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) !important;
}
